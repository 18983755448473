import React, { useEffect } from 'react';

const Instagram = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    document.body.appendChild(script);
  }, []);

  const blockquoteStyle = {
    background: '#FFF',
    border: '0',
    borderRadius: '3px',
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.5), 0 1px 10px 0 rgba(0,0,0,0.15)',
    margin: '1px',
    maxWidth: '540px',
    minWidth: '326px',
    padding: '0',
    width: '99.375%'
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2 style={{ fontFamily: 'Playfair Display, serif' }}>Art By RRL Gallery</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px', maxWidth: '80%' }}>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/C1rzqYvNqHB/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/C1rx_07tVtR/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CrIyfOsN-rq/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CrIyRRvNgT5/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CrIx2rmNXdo/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CrIxladNxQ2/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CrIxVL2twgY/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CDS-NArhF2P/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CTr8CAYMFVF/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CSSBDBDs_ix/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CGPjng5BX5q/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <blockquote className="instagram-media" data-instgrm-permalink="https://www.instagram.com/p/CCyruLiB6ey/?utm_source=ig_embed&utm_campaign=loading" data-instgrm-version="14" style={blockquoteStyle}></blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instagram;
