
import React from 'react';
import GiphyGallery from './GiphyGallery';
import Instagram from './Instagram';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '100px',
  },
  navbar: {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#fff',
    zIndex: 1000,
    padding: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  content: {
    marginTop: '60px', 
  }
};


const Portfolio = () => {
  return (
      <div style={styles.container}>
        <GiphyGallery />
        <Instagram />
      </div>
  );
};

export default Portfolio;
