import React from 'react';

const Blog = () => {
  return (
    <div>
      <h1>Blog</h1>
      <p>Welcome to my blog! Here, I share my creative process, tips, and updates on new projects.</p>
    </div>
  );
};

export default Blog;
