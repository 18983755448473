// src/components/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <div className="container">
        <h2>About Me</h2>
        <p>Welcome to my personal corner of the internet! I'm Rachel Levi, a passionate creator with a deep love for technology and visual arts. With a background in Computer Science and extensive experience in implementation operations and deployment QA, I've dedicated my career to bridging the gap between technical solutions and user-friendly applications.</p>
        <p>My professional journey has led me to roles such as Implementation Operations Analyst and Deployment QA Engineer at Contentsquare. Here, I optimized workflows, managed knowledge processes, and performed extensive QA testing for enterprise applications. This experience has equipped me with the skills to tackle challenging problems and implement effective solutions that enhance user experience and operational efficiency.</p>
        <p>When I'm not immersed in the world of technology, you can find me exploring new creative outlets. I enjoy creating digital art and animations, and you can see some of my work on my Giphy profile. I believe in the power of visual storytelling and strive to combine my technical expertise with creative expression to deliver impactful and engaging experiences.</p>
      </div>
    </div>
  );
};

export default About;
