import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_o1x8i8t', 'template_dec9rxn', form.current, 'iq18xyZYJCBIadU-N')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('An error occurred, please try again.');
      });
  };

  return (
<div style={{ aligntext:'center' ,display: 'flex', justifyContent: 'space-between', padding: '20px', fontFamily: 'Arial, sans-serif', maxWidth: '540px',
    minWidth: '326px', }}>
<div style={{ aligntext:'center', flex: 1, padding: '20px', backgroundColor: '#f7f7f7', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }}>
<h2 style={{ fontFamily: 'Playfair Display, serif' }}>Contact Me</h2>
 <p>Feel free to reach out to me via the form below or through my social media channels.</p>
      <div style={{ flex: 1, marginRight: '20px' }}>
        <form ref={form} onSubmit={sendEmail} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>Name</label>
            <input type="text" name="name" style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
            <div style={{ flex: '0 0 48%' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Email</label>
              <input type="email" name="email" style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }} />
            </div>
            <div style={{ flex: '0 0 48%' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Phone Number</label>
              <input type="tel" name="phone" style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }} />
            </div>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>Message</label>
            <textarea name="message" rows="5" style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }}></textarea>
          </div>
          <button type="submit" style={{ padding: '10px 20px', backgroundColor: 'black', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Submit</button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default Contact;

