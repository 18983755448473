// src/components/GiphyGallery.js
import React from 'react';

const GiphyGallery = () => {
  const gifStyle = {
    background: '#FFF',
    border: '0',
    borderRadius: '3px',
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.5), 0 1px 10px 0 rgba(0,0,0,0.15)',
    margin: '1px',
    maxWidth: '540px',
    minWidth: '326px',
    padding: '0',
    width: '99.375%'
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2 style={{ fontFamily: 'Playfair Display, serif' }}>My Giphy Gallery</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px', maxWidth: '80%' }}>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/tnNI84i9DXUYqdqp12" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 1"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/tA5XyhqhzDuk8aoNFF" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 2"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/03mEuico3f3JHlDbsM" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 3"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/qM8kaLlr5pqp7AoVXG" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 4"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/6wx6IsIw8BgRuhAlsq" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 5"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/jdtfstLIq1T6u6HU3O" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 6"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/99JupAtp8sNqEFHh9s" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 7"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/MZdYV0okNh4jbURAcr" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 8"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/EgHEkxtyCJONa79ea5" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 9"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/Uxh8u3lEzY2BsDu58n" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 10"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/Tzstx0wv1Siztoaybi" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 11"></iframe>
          </div>
          <div style={{ margin: '10px', ...gifStyle }}>
            <iframe src="https://giphy.com/embed/Rjc9245TTVAMEIIJLA" width="150" height="150" frameBorder="0" className="giphy-embed" allowFullScreen title="Gif 12"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiphyGallery;
